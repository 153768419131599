'use strict';

import { handleActions } from 'redux-actions';
import { guideRouteChange } from './actions';

const initialState = {
  route: null,
};

export const guideRoute = handleActions({
  [guideRouteChange] (prevState, action) {
    return {
      ...prevState,
      route: { ...action.payload },
    };
  },
}, initialState);
