'use strict';
import { createAction } from 'redux-actions';

const MODAL_SHOW = 'MODAL_SHOW';
const MODAL_HIDE = 'MODAL_HIDE';
const MODAL_LAYOUT_CHANGED = 'MODAL_LAYOUT_CHANGED';
const MODAL_SET_BUSY = 'MODAL_SET_BUSY';

export const modalShow = createAction(MODAL_SHOW);
export const modalHide = createAction(MODAL_HIDE);
export const modalLayoutChanged = createAction(MODAL_LAYOUT_CHANGED);
export const modalSetBusy = createAction(MODAL_SET_BUSY);
