'use strict';

import { handleActions } from 'redux-actions';
import { modalShow, modalHide, modalLayoutChanged, modalSetBusy } from './actions';

// initial redux store state for portal modal
const initialState = {
  visible:       false,
  busy:          {value: false, text: null},
  layoutChanged: Date.now(),
  data:          { id: '', title: '' },
};

export const modal = handleActions({
  [modalShow] (prevState, action) {
    return {
      ...prevState,
      visible: true,
      data: action.payload,
    };
  },
  [modalHide] (prevState, action) {
    return {
      ...initialState,
    };
  },
  [modalLayoutChanged] (prevState, action) {
    return {
      ...prevState,
      layoutChanged: Date.now(),
    };
  },
  [modalSetBusy] (prevState, action) {
    const { value = false, text = null } = typeof action.payload === 'object' ? { ...action.payload } : { value: !!action.payload, text: null };
    return {
      ...prevState,
      busy: {
        value,
        text,
      },
    };
  },
}, initialState);
