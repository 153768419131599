'use strict';

import { handleActions } from 'redux-actions';
import { routeChange } from './actions';

const initialState = {
  route: null,
};

export const publicRoute = handleActions({
  [routeChange] (prevState, action) {
    return {
      ...prevState,
      route: { ...action.payload },
    };
  },
}, initialState);
