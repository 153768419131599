'use strict';
import { createAction } from 'redux-actions';

const INITIALIZED = 'INITIALIZED';
const APP_NOTIFICATION_SEEN = 'APP_NOTIFICATION_SEEN';
const APP_NOTIFICATION_PAUSED = 'APP_NOTIFICATION_PAUSED';

export const initialized = createAction(INITIALIZED);
export const appNotificationSeen = createAction(APP_NOTIFICATION_SEEN);
export const appNotificationPaused = createAction(APP_NOTIFICATION_PAUSED);
