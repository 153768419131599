'use strict';

import { handleActions } from 'redux-actions';
import {
  initialized,
  appNotificationSeen,
  appNotificationPaused,
} from './actions';

const initialState = {
  initialized: false,
  appNotificationSeen: 0,
  appNotificationPaused: false,
};

export const application = handleActions({
  [initialized] (prevState, action) {
    return {
      ...prevState,
      initialized: true,
    };
  },
  [appNotificationSeen] (prevState, action) {
    return {
      ...prevState,
      appNotificationSeen: action.payload,
    };
  },
  [appNotificationPaused] (prevState, action) {
    return {
      ...prevState,
      appNotificationPaused: action.payload,
    };
  },
}, initialState);
