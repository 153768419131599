'use strict';

import 'react-app-polyfill/ie11';

import { initReactI18next } from 'react-i18next';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from 'portal/redux/store';
import { initialized } from "./portal/react-application/actions";

import 'bootstrap.css';
import 'font-awesome.css';
import 'bootstrap-datetimepicker.min.css';

import 'portal.checkbox-radio-switch.css';
import 'portal.select2.css';
import 'portal.select2-bootstrap.css';
import 'portal.switchery.css';
import 'portal.netopicons.css';
import 'style.css';
import 'portal.default.css';

require([
  'portal/token-manager',
  'portal/util/env',
  'portal/util/util',
  'jquery',
  'xss-filters',
  'dust',
  'portal/util/dust.helpers',
  'switchery',
  'core-js/modules/es6.promise'
], function setupJquery (tokenManager, env, util) {
  env.done(function whenEnvIsLoaded () {
    require([
      'i18n',
      'moment',
      'underscore',
      'i18next-xhr-backend',
      'i18next-chained-backend',
      'i18next-localstorage-backend',
    ], function setupJqueryAjaxAndStart (i18n, moment, _) {
      _.partial.placeholder = _;
      var APP_VERSION = NETOP_CONFIG.PORTAL_VERSION;

      var ChainedBackend = require('i18next-chained-backend');
      var XhrBackend = require('i18next-xhr-backend');
      var LocalStorageBackend = require('i18next-localstorage-backend');

      if (env && env.data && env.data.apiconfig && env.data.apiconfig['web-authenticate']) {
        tokenManager.setAuthServiceLocation(env.data.apiconfig['web-authenticate']);
      }

      if (!_.isUndefined(localStorage)) {
        var storedVersion = localStorage.getItem('version');

        if (APP_VERSION !== storedVersion || localStorage.getItem('i18next_res_en-translation')) {
          localStorage.setItem('version', APP_VERSION);
          _.each(_.keys(localStorage), function (key) {
            if (-1 !== key.indexOf('i18next_res_')) {
              localStorage.removeItem(key);
            }
          });
        }
      }

      if (!window.location.origin) {
        window.location.origin = window.location.protocol + '//'
          + window.location.hostname
          + (window.location.port ? (':' + window.location.port) : '');
      }

      var isClientAggressiveCacher = function fIsClientAggressiveCacher () {
        return (
          (0 <= window.navigator.userAgent.indexOf('MSIE'))
          || !!navigator.userAgent.match(/Trident.*rv\:11\./)
          || (0 <= window.navigator.userAgent.indexOf('Edge'))
        );
      };

      $.ajaxSetup({
        statusCode: {
          401: function on401Response (jqXhr) {
            tokenManager.redirectToLoginService({
              error:  jqXhr.getResponseHeader('X-Error'),
              logout: false
            });
          }
        },
        cache: !isClientAggressiveCacher()
      });

      moment.locale('en');

      i18n
      .use(ChainedBackend)
      .use(initReactI18next)
      .init({
        backend: {
          backends: [ LocalStorageBackend, XhrBackend ],
          backendOptions: [{
            prefix:         'i18next_res_',
            expirationTime: 60 * 60 * 1000,
          }, {
            loadPath:      '/lang/__lng__.json',
            customHeaders: {
              'Cache-Control': 'no-cache',
            },
            queryStringParams: {
              v: APP_VERSION,
            },
          }]
        },
        interpolation: {
          escapeValue: false,
          prefix:      '__',
          suffix:      '__',
        },
        fallbackLng:                'en',
        lng:                        'en',
        preload:                    ['en'],
      }, function onLoadedLanguageFiles (error) {
        const { PortalApplication } = require('portal/react-application/application');
        ReactDOM.render(
          <Provider store={store}>
            <PortalApplication />
          </Provider>,
          document.getElementById('portal-application')
        );
        require([
          'backbone',
          'portal/guest/publicRouter',
          'portal/guest/publicRoutes',
          'php',
          'bootstrap',
        ], function routerLoaded(
          Backbone
          , publicRouter
          , publicRoutes
          , php) {
          const hosts = Object.keys(publicRoutes)
            .filter((key) => !!publicRoutes[key].hostname)
            .reduce((acc, key) => {acc[publicRoutes[key].hostname] = true; return acc;}, {});

          var isPublicRoute = _.some(publicRoutes, function(routeObj) {
            if (routeObj.hostname) {
              if (window.location.hostname.toLowerCase() !== routeObj.hostname) {
                return false;
              }
            } else {
              if (hosts[window.location.hostname.toLowerCase()]) {
                return false;
              }
            }
            var routeRegExp = publicRouter._routeToRegExp(routeObj.route);
            return routeRegExp.test(php.trim(window.location.pathname, '/'));
          });
          if (isPublicRoute) {
            env.isPublicRoute = true;
            Backbone.history.start({ pushState: true });
            return;
          }
          // remember, public routes are still binded in Backbone.history but did not match

          require(['portal/application', 'portal/bootstraper', 'bootstrap'], function onBootstraperLoaded (application, portalBootstraper) {
            portalBootstraper.bootstrap(application);
            application.once('bootstrap:end', function () {
              application.applyWhiteLabel({ auto: true })
              application.on('all', _.debounce(function () {
                tokenManager.prolongTokenTTL();
              }, 500));
              // tell react app was initialized
              store.dispatch(initialized());
            });
            tokenManager.prolongTokenTTL();

            // intercept clicks and keydown to prolong token;
            // if token is expired cancel the event
            const userInteractionCheckToken = (event) => {
              if (!tokenManager.getAuthenticationToken()) {
                tokenManager.redirectToLoginService({ logout: false });
                event.stopImmediatePropagation();
                event.preventDefault();
                return false;
              }
            };
            $(document).on('click', userInteractionCheckToken);
            $(document).on('keydown', userInteractionCheckToken);

            // monitor user token to display a warning message when just 1 minute remains until expiration
            tokenManager.on('token:monitor', ({ value, expires, expiresAfter }) => {
              if (!value || !expires || expiresAfter === 0) {
                // token is expired
                $('#expiration').remove();
              } else {
                let $exp = $('#expiration');
                if (expiresAfter < (NETOP_CONFIG.TOKEN_EXPIRATION_MESSAGE_TIMEOUT || 60000)) {
                  // one minute until expiration; display message
                  if ($exp.length === 0) {
                    dust.render(require('common/layout/expiration.dust'), {}, (error, html) => {
                      $('body').append(html);
                      $exp = $('#expiration');
                      const removeWarning = () => {
                        $exp.remove();
                        tokenManager.prolongTokenTTL();
                      };
                      $exp.on('click', removeWarning);
                      $exp.on('keydown', removeWarning);
                      $exp.addClass('visible');
                    });
                  }
                  $exp.focus();
                  $('#expiration-message').html(
                    i18n.t(
                      'common_expire-message',
                      { expire: util.formatSinceDuration(Date.now(), expires)}
                    )
                  );
                } else {
                  // token ok; remove message
                  $('#expiration').remove();
                }
              }
            });
            tokenManager.monitorExpiration();
          });
        });
      });
    });
  });
});
